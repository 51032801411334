#sidebar {
  position: absolute;
}

#sidebar .closemenu {
  color: #fff;
  position: fixed;
  left: 20;
  margin-left: 30px;
  z-index: 9999;
  line-height: 20px;
  border-radius: 50%;
  font-weight: bold;
  font-size: 30px;
  top: 15px;
  cursor: pointer;
}

#sidebar .pro-sidebar {
  width: 250px;
  min-width: 260px;
  height: 100vh;
  position: fixed;
}


#sidebar .pro-sidebar.collapsed {
  width: 90px !important;
  min-width: 90px !important;
}

#sidebar .pro-sidebar-inner {
  background-color: #993366;
  box-shadow: 0.5px 0.866px 2px 3px #00000026;
}
#sidebar .pro-sidebar-inner .pro-sidebar-layout {
  overflow-y: auto;
}
#sidebar .pro-sidebar-inner .pro-sidebar-layout .logotext p {
  font-size: 20px;
  padding: 0 20px;
  color: #000;
  font-weight: bold;
}
#sidebar .pro-sidebar-inner .pro-sidebar-layout ul {
  padding: 0 5px;
}
#sidebar .pro-sidebar-inner .pro-sidebar-layout ul .pro-inner-item {
  color: #000;
  /* margin: 0px; */
  /* padding-bottom: 3px; */
  font-weight: bold;
  font-size: 13px;
}
#sidebar
  .pro-sidebar-inner
  .pro-sidebar-layout
  ul
  .pro-inner-item
  .pro-icon-wrapper {
  background-color: #222527;
  color: #993366;
  border-radius: 2px;
}

#sidebar
  .pro-sidebar-inner
  .pro-sidebar-layout
  ul
  .pro-inner-item
  .pro-icon-wrapper
  .pro-item-content {
  color: white;
}
#sidebar .pro-sidebar-inner .pro-sidebar-layout .active {
  /* background-color: #234E70; */
  background-color: inherit;
  color: #000;
  border-bottom: 4px solid #D16587;
  padding: 5px;
}
#sidebar .pro-sidebar-inner .pro-sidebar-layout a {
  color: #212529;
  font-size: 14px;
}
#sidebar .logo {
  padding: 20px;
}

#sidebar_notification .closemenu {
  color: #fff;
  position: fixed;
  right: 0px;
  z-index: 9999;
  line-height: 20px;
  border-radius: 50%;
  font-weight: bold;
  font-size: 30px;
  top: 0px;
  cursor: pointer;
}
#sidebar_notification .pro-sidebar {
  width: 250px;
  min-width: 270px;
  height: 100vh;
  right:0;
  position: fixed;
}
#sidebar_notification .pro-sidebar.collapsed {
  width: 0px;
  min-width: 0px;
}

#sidebar_notification .pro-sidebar-inner {
  background-color: #993366;
  box-shadow: 0.5px 0.866px 2px 2px #00000026;
}
#sidebar_notification .pro-sidebar-inner .pro-sidebar-layout {
  overflow-y: auto;
}
#sidebar_notification .pro-sidebar-inner .pro-sidebar-layout .logotext p {
  font-size: 12px;
  padding: 0 5px;
  color: #000;
  font-weight: bold;
}
#sidebar_notification .pro-sidebar-inner .pro-sidebar-layout ul {
  padding: 0 2px;
}
#sidebar_notification .pro-sidebar-inner .pro-sidebar-layout ul .pro-inner-item {
  color: #000;
  margin: 5px 0px;
  font-weight: bold;
}
#sidebar_notification
  .pro-sidebar-inner
  .pro-sidebar-layout
  ul
  .pro-inner-item
  .pro-icon-wrapper {
  background-color: #eeeeee;
  color: #000;
  border-radius: 3px;
}
#sidebar_notification
  .pro-sidebar-inner
  .pro-sidebar-layout
  ul
  .pro-inner-item
  .pro-icon-wrapper
  .pro-item-content {
  color: #000;
}
#sidebar_notification .btn-close{
  display: none;
}

@media only screen and (max-width: 720px) {
  html {
    overflow: auto;
  }

}

::-webkit-scrollbar {
  width: 12px;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3); 
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5); 
}

.css-dip3t8 {
  background-color: transparent !important;
}
.css-o97eqh >.ps-menu-button:hover{
color: black !important;
}

.sc-dlfmHC.cfvlby.rdt_TableHeadRow{
background-color: #EAF6F9 !important;
}
.ofEne{
font-weight: bolder !important;
font-size: 16px;
}