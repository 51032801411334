.App {
  text-align: center;
}

.eHqgZP {
  overflow: auto;
  
}

.eHqgZP::-webkit-scrollbar {
    all: initial !important;
  
    background: #d8d8d8 !important;
}
.eHqgZP::-webkit-scrollbar-thumb {
    all: initial !important;
    background: #7c7c7c !important;
}
.eHqgZP::-webkit-scrollbar-button { all: initial !important; }
.eHqgZP::-webkit-scrollbar-track { all: initial !important; }
.eHqgZP::-webkit-scrollbar-track-piece { all: initial !important; }
.eHqgZP::-webkit-scrollbar-corner { all: initial !important; }
.eHqgZP::-webkit-resizer { all: initial !important; }


.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

input:focus {
  border: 1px solid #3182ce !important;
  outline: #3182ce !important;
}
.otp_border-color {
  border: 1px solid #2ca1cb;
}
table,
th,
td {
  border: 1px solid black;
  border-collapse: collapse;
}

th,
td {
  padding: 20px;
}

th {
  text-align: left;
}

nav .active a,
nav .active a:hover {
  color: #003942 !important;
}
nav li:hover a {
  color: #003942 !important;
}
nav li:hover a {
  font-weight: bold !important;
}
