@tailwind base;
@tailwind components;
@tailwind utilities;

body{
    background-color: #F5F4F0 !important;
    font-family: Atlas Grotesk,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica,Arial,sans-serif;
}

.rotations:hover::after {
 
    animation: rotateBack 0.3s ease forwards; /* Smooth transition back */
}
.rotations:hover{

    transition: transform 0.3s; 
    animation: rotateY 2s linear infinite;
}

.hover\:shake:hover {
  animation: shake 1s ease-in-out; /* Adjust the animation duration as needed */
  animation-iteration-count: 1; /* Play the animation once */
}


@keyframes rotateY {
    0% {
        transform: rotateY(0deg);
    }
    100% {
        transform: rotateY(360deg); /* Rotate 360 degrees over the animation duration */
    }
}

@keyframes rotateBack {
    to {
        transform: rotateY(0deg); /* Rotate back to 0 degrees */
    }

}
@keyframes shake {
    0% { transform: translateX(0); }
    10% { transform: translateX(-3px); }
    20% { transform: translateX(3px); }
    30% { transform: translateX(-3px); }
    40% { transform: translateX(3px); }
    50% { transform: translateX(-3px); }
    60% { transform: translateX(3px); }
    70% { transform: translateX(0px); }
    80% { transform: translateX(0px); }
    90% { transform: translateX(0px); }
    100% { transform: translateX(0); }
  }
  .loader-container {
    top: 0px;
    left: 0px;
    position: fixed;
    text-align: center;
    background-color: #000;
    width: 100%;
    height: 100%;
    opacity: 0.5;
  
    .spinner {
      animation: rotate 2s linear infinite;
      z-index: 2;
      position: absolute;
      top: 50%;
      left: 50%;
      margin: -25px 0 0 -25px;
      width: 50px;
      height: 50px;
  
      & .path {
        stroke: hsl(210, 70, 75);
        stroke-linecap: round;
        animation: dash 1.5s ease-in-out infinite;
      }
    }
  
    @keyframes rotate {
      100% {
        transform: rotate(360deg);
      }
    }
  
    @keyframes dash {
      0% {
        stroke-dasharray: 1, 150;
        stroke-dashoffset: 0;
      }
      50% {
        stroke-dasharray: 90, 150;
        stroke-dashoffset: -35;
      }
      100% {
        stroke-dasharray: 90, 150;
        stroke-dashoffset: -124;
      }
    }
  }
  